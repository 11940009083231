import { CONFIG, GridColDef, GridRenderCellParams, GridRowId, GridRowModes, GridRowModesModel, Link } from '@sgde/core';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ResourceDto, ResourceState as ResourceStateEnum } from '../../../models/dto/chat/resource.ts';
import { useOrganisations } from '../../../store/organisationApi.ts';
import { useDeleteResource, useUpdateResource } from '../../../store/resourceApi.ts';
import { IdentifierEditCell } from '../cells/IdentifierEditCell.tsx';
import { OrganisationsEditCell } from '../cells/OrganisationsEditCell.tsx';
import { ResourceState } from '../cells/ResourceState.tsx';
import { useStyles } from '../ResourcesTable.styles.ts';
import { useActions } from './useResourcesActions.tsx';

interface IColumnProps {
  rowModesModel: GridRowModesModel;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  resources?: ResourceDto[];
}
export const useFileResourcesColumns = ({ rowModesModel, setRowModesModel, resources }: IColumnProps) => {
  const { classes, cx } = useStyles();
  const { data: organisations } = useOrganisations();
  const [deleteResource] = useDeleteResource();
  const [updateResource] = useUpdateResource();

  const updateResourceState = (id: GridRowId, newState: keyof typeof ResourceStateEnum) => {
    const resourceToUpdate = resources?.find(resource => resource.id === id);
    if (resourceToUpdate) {
      const updatedResource = { ...resourceToUpdate, state: newState };
      updateResource(updatedResource);
    }
  };

  const { getDefaultActions, getEditActions } = useActions({
    setRowModesModel,
    updateResource: updateResourceState,
    deleteResource,
  });

  const organisationOptions = useMemo(
    () => [{ id: undefined, name: 'Personal' }, ...(organisations?.map(({ id, name }) => ({ id, name })) ?? [])],
    [organisations]
  );

  const fileColumns = [
    {
      field: 'title',
      headerName: 'Titlu',
      disableColumnMenu: true,
      editable: true,
      flex: 3,
      renderCell: ({ row }: GridRenderCellParams<ResourceDto>) => (
        <Link
          className={cx({ [classes.disabledLink]: !row.state })}
          href={`${CONFIG.BASE_API_URL}/chat/resources/${row.id}/download`}
          underline="hover"
          color="inherit"
          target="_blank"
        >
          {row.title}
        </Link>
      ),
    },
    { field: 'author', headerName: 'Autor', disableColumnMenu: true, editable: true, flex: 2 },
    { field: 'identifierType', headerName: 'Tip', disableColumnMenu: true, editable: true },
    {
      field: 'identifier',
      headerName: 'Identificator',
      disableColumnMenu: true,
      editable: true,
      flex: 2.5,
      renderCell: ({ row }: GridRenderCellParams<ResourceDto>) => (
        <>
          {row.identifierType ? <b>{row.identifierType}: </b> : ''}
          <i>{row.identifier}</i>
        </>
      ),
      renderEditCell: props => <IdentifierEditCell {...props} />,
    },
    {
      field: 'state',
      headerName: 'Stare',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ value }) => <ResourceState state={value} />,
    },
    {
      field: 'organisationIds',
      headerName: 'Organizatii',
      disableColumnMenu: true,
      flex: 3,
      editable: true,
      type: 'custom',
      valueOptions: organisationOptions,
      renderEditCell: props => <OrganisationsEditCell {...props} />,
      valueFormatter: (ids: number[]) =>
        organisationOptions
          .reduce(
            (selected, org) => (org.id && ids?.includes(org.id) ? [...selected, org.name] : selected),
            [] as string[]
          )
          .join(', ') || 'Personal',
    },
    { field: 'addedByUserEmail', headerName: 'Adaugat de', disableColumnMenu: true, flex: 2 },
    {
      field: 'createdAt',
      headerName: 'Adaugat la',
      type: 'date',
      valueGetter: value => new Date(value),
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'actions',
      type: 'actions',
      resizable: false,
      getActions: ({ id, row }) =>
        rowModesModel[id]?.mode === GridRowModes.Edit ? getEditActions(id) : getDefaultActions(id, row),
    },
  ] as GridColDef[];

  return {
    fileColumns,
  };
};
