import { makeStyles } from '@sgde/core';

type StyleProps = {
  counterMessage?: string;
  counterStatus: 'success' | 'info' | 'warning' | 'error';
};

export const useStyles = makeStyles<StyleProps>()((theme, { counterMessage, counterStatus }) => ({
  root: {
    height: '100%',
    width: '100%',

    '> div': {
      height: '100%',
      gap: theme.spacing(1),
    },
  },
  inputWrapper: {
    width: '100%',
    marginTop: 'auto',
    paddingTop: theme.spacing(1 / 4),
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      gap: theme.spacing(1 / 2),
      flexWrap: 'wrap',
    },
    '& .MuiInputBase-input': {
      order: -1,
      borderStyle: 'solid',
      borderBottomWidth: 'thin',
      borderColor: theme.palette.divider,
      paddingBottom: theme.spacing(1 / 2),
      marginBottom: theme.spacing(1 / 2),
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '&::before': {
      zIndex: 10,
      position: 'absolute',
      top: theme.spacing(-1.5),
      right: theme.spacing(1.2),
      padding: theme.spacing(0.5),
      content: `"${counterMessage}"`,
      color: theme.palette[counterStatus].main,
      fontSize: theme.typography.caption.fontSize,
      backgroundColor: theme.palette.background.paper,
    },
  },
  adornmentEnd: {
    marginLeft: 'auto',
  },
  info: {
    alignItems: 'center',
    gap: theme.spacing(1 / 2),
    marginTop: theme.spacing(1),
  },
}));
