import { BaseDto, baseApi } from '@sgde/core';
import { ResourceDto } from '../models/dto/chat/resource.ts';

const resourceApi = baseApi.enhanceEndpoints({ addTagTypes: ['Resources'] }).injectEndpoints({
  endpoints: build => ({
    uploadFiles: build.mutation<ResourceDto[], File[]>({
      query: files => {
        const body = new FormData();
        files.forEach(file => body.append('files', file));

        return {
          url: '/chat/resources/file',
          method: 'POST',
          body,
        };
      },
    }),
    getResources: build.query<ResourceDto[], void>({
      query: () => '/chat/resources',
      providesTags: ['Resources'],
    }),
    createResource: build.mutation<ResourceDto, File>({
      query: file => {
        const body = new FormData();
        body.append('file', file);

        return {
          url: `/chat/resources`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(file, { dispatch, queryFulfilled }) {
        let fileId = -1;
        const patchResult = dispatch(
          resourceApi.util.updateQueryData('getResources', undefined, resources => {
            fileId -= resources.length;
            resources.unshift({ id: fileId, title: file.name, createdAt: new Date().toString() });
          })
        );
        try {
          const { data: createdResource } = await queryFulfilled;
          dispatch(
            resourceApi.util.updateQueryData('getResources', undefined, resources => {
              const resource = resources.find(file => file.id === fileId);
              if (resource) {
                Object.assign(resource, createdResource);
              }
            })
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateResource: build.mutation<void, ResourceDto>({
      query: resource => ({
        url: `/chat/resources/${resource.id}`,
        method: 'PUT',
        body: resource,
      }),
      invalidatesTags: ['Resources'],
    }),
    deleteResource: build.mutation<void, BaseDto>({
      query: ({ id }) => ({ url: `/chat/resources/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Resources'],
    }),
  }),
});

export const {
  useUploadFilesMutation: useUploadFiles,
  useGetResourcesQuery: useResources,
  useCreateResourceMutation: useCreateResource,
  useUpdateResourceMutation: useUpdateResource,
  useDeleteResourceMutation: useDeleteResource,
} = resourceApi;
