import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    order: 10,
    flexDirection: 'row',
    flexBasis: '100%',
    flexWrap: 'wrap',
    gap: theme.spacing(1 / 2),
    paddingTop: theme.spacing(1 / 2),
  },
}));
