import { alpha, makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  message: {
    gap: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      gap: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      '& > *': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    '& pre code.hljs': {
      padding: theme.spacing(2),
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.1),
    },
    '& table': {
      padding: theme.spacing(2),
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.1),
      '& th, td': {
        padding: theme.spacing(1 / 2),
        background: theme.palette.background.default,
      },
    },

    '& .caption-id': {
      padding: theme.spacing(1 / 2),
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.caption.fontSize,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.35),
    },

    '& .caption-resource': {
      display: 'inline-block',
      marginRight: theme.spacing(1 / 2),
      marginTop: theme.spacing(1 / 2),
      padding: theme.spacing(1 / 2),
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.caption.fontSize,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.35),

      '& a': {
        paddingRight: theme.spacing(1 / 2),
        paddingLeft: theme.spacing(1 / 2),
        color: theme.palette.text.primary,
      },
    },
  },

  avatar: {
    alignSelf: 'start',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
}));
