import {
  AddIcon,
  ArrowDropDownIcon,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  GridRowModes,
  GridRowModesModel,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useLocation,
  useNavigate,
} from '@sgde/core';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { RESOURCE_TYPES } from '../../constants/resources.ts';
import { useCreateResource } from '../../store/resourceApi.ts';
import { useCreateWebPageResourceLocally } from '../../store/webPageResourceApi.ts';
import { useStyles } from './TableToolbar.styles.ts';

interface IProps {
  selectedMenu: string;
  setSelectedMenu: Dispatch<SetStateAction<string>>;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  setPaginationModel: Dispatch<SetStateAction<{ page: number; pageSize: number }>>;
}

export const Toolbar = ({ selectedMenu, setSelectedMenu, setRowModesModel, setPaginationModel }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [createFileResource, { isLoading: isCreatingFileResource }] = useCreateResource();
  const [createWebPageResourceLocally, { data: webPageResource }] = useCreateWebPageResourceLocally();

  useEffect(() => {
    if (location?.state?.init && selectedMenu !== location?.state?.init) {
      handleMenuItemClick(location?.state?.init);
    } else if (location?.state?.init) {
      handleClick(location?.state?.init);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (webPageResource) {
      setRowModesModel(model => ({
        ...model,
        [webPageResource.id!]: { mode: GridRowModes.Edit, fieldToFocus: 'title' },
      }));
    }
  }, [webPageResource, setRowModesModel]);

  const handleMenuItemClick = (menuItem: string) => {
    localStorage.setItem('resourceType', menuItem);
    setSelectedMenu(menuItem);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (!anchorRef.current || !anchorRef.current.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  };

  const addFileResource = (event: ChangeEvent<HTMLInputElement>) => {
    const files = [...(event.target.files ?? [])];
    files.forEach(file => {
      const updateMode = async () => {
        const result = await createFileResource(file);
        if ('data' in result) {
          setRowModesModel(model => ({
            ...model,
            [result.data!.id!]: { mode: GridRowModes.Edit, fieldToFocus: 'title' },
          }));
        }
      };
      updateMode();
    });
    setPaginationModel(pagination => ({ ...pagination, page: 0 }));
  };

  const handleClick = (option?: string) => {
    switch (option || selectedMenu) {
      case RESOURCE_TYPES.FILE:
        fileUploadRef.current?.click();
        break;
      case RESOURCE_TYPES.WEB_PAGE:
        createWebPageResourceLocally();
        setPaginationModel(pagination => ({ ...pagination, page: 0 }));
        break;
      case RESOURCE_TYPES.DATABASE:
        console.log('WIP');
        break;
      default:
        console.error('Invalid menu option');
    }
  };

  return (
    <Box className={classes.root}>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
        <Button onClick={() => handleClick()} startIcon={<AddIcon />}>
          {selectedMenu}
          <input
            multiple
            type="file"
            ref={fileUploadRef}
            accept=".pdf, .csv, .txt"
            onChange={addFileResource}
            className={classes.hiddenInput}
            disabled={isCreatingFileResource}
          />
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select an option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {Object.values(RESOURCE_TYPES).map(option => (
                    <MenuItem
                      key={option}
                      disabled={option === RESOURCE_TYPES.DATABASE}
                      selected={option === selectedMenu}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
