import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SendIcon,
  TemplateIcon,
  Typography,
  UploadIcon,
  useNavigate,
} from '@sgde/core';
import { MouseEvent, useRef, useState } from 'react';
import { RESOURCE_TYPES } from '../../constants/resources.ts';
import { ResourceDto } from '../../models/dto/chat/resource.ts';
import { ConversationTemplateDto } from '../../models/dto/chat/template.ts';
import { ROUTES } from '../../router/routes.ts';
import { useConversationTemplates } from '../../store/chatApi.ts';
import { useStyles } from './ConversationActions.styles.ts';
import { MessageFiles } from './MessageFiles.tsx';

type ConversationResourcesProps = {
  files: ResourceDto[];
  addFiles: (files: File[]) => void;
  removeFile: (file: ResourceDto) => void;
  setRequest: (request: string) => void;
};

export const ConversationActions = ({ files, addFiles, removeFile, setRequest }: ConversationResourcesProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [uploadMenuAnchor, setUploadMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [templatesMenuAnchor, setTemplatesMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const { data: templates } = useConversationTemplates();

  const openUploadMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setUploadMenuAnchor(event.currentTarget);
  };

  const openTemplatesMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setTemplatesMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setUploadMenuAnchor(null);
    setTemplatesMenuAnchor(null);
  };

  const onFileUpload = () => {
    handleClose();
    fileUploadRef.current?.click();
  };

  const onTemplateSelect = (template: ConversationTemplateDto) => {
    setRequest(template.message);
    handleClose();
  };

  return (
    <>
      <IconButton disableRipple onClick={openUploadMenu} size="small">
        <UploadIcon fontSize="small" />
      </IconButton>
      <IconButton disableRipple onClick={openTemplatesMenu} size="small">
        <TemplateIcon fontSize="small" />
      </IconButton>
      <Menu
        keepMounted
        open={!!uploadMenuAnchor}
        anchorEl={uploadMenuAnchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={onFileUpload}>
            Fisier
            <input
              multiple
              type="file"
              ref={fileUploadRef}
              accept=".pdf, .csv, .txt"
              onChange={e => addFiles([...(e.target.files ?? [])])}
              className={classes.hiddenInput}
            />
          </ListItemText>
        </MenuItem>
        <Divider textAlign="center">
          <Typography variant="caption" color="textSecondary">
            In colectia mea
          </Typography>
        </Divider>
        <MenuItem onClick={() => navigate(ROUTES.RESOURCES.path)}>
          <ListItemText>Vezi toate resursele</ListItemText>
        </MenuItem>
        {Object.values(RESOURCE_TYPES).map(option => (
          <MenuItem
            key={option}
            disabled={option === RESOURCE_TYPES.DATABASE}
            onClick={() => navigate(ROUTES.RESOURCES.path, { state: { init: option } })}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        keepMounted
        open={!!templatesMenuAnchor}
        anchorEl={templatesMenuAnchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Divider textAlign="center">
          <Typography variant="caption" color="textSecondary">
            Generale
          </Typography>
        </Divider>
        {templates?.map(template => (
          <MenuItem key={template.id} onClick={() => onTemplateSelect(template)}>
            {template.title}
          </MenuItem>
        ))}
      </Menu>
      {files?.length ? <MessageFiles files={files} removeFile={removeFile} /> : null}
    </>
  );
};
