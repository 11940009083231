import { Alert, Box, IconButton, InfoOutlinedIcon, SendIcon, Stack, TextField, Typography, useSite } from '@sgde/core';
import { useMemo, useState } from 'react';
import { ConversationDto } from '../../models/dto/chat/conversation.ts';
import { ResourceDto } from '../../models/dto/chat/resource.ts';
import { useCreateConversation, useMessageResponse, useUserChatInfo } from '../../store/chatApi.ts';
import { useUploadFiles } from '../../store/resourceApi.ts';
import { useStyles } from './Conversation.styles.ts';
import { ConversationActions } from './ConversationActions.tsx';
import { Messages } from './Messages.tsx';

type Props = {
  conversation: ConversationDto;
  organisationId?: number;
};

export const Conversation = ({ conversation, organisationId }: Props) => {
  const { data: site } = useSite();
  const [getResponse, { isCompleted: isResponding, lastConversationIdError }] = useMessageResponse();
  const [request, setRequest] = useState('');
  const [createConversation] = useCreateConversation();
  const [files, setFiles] = useState<ResourceDto[]>([]);
  const [uploadFiles, { isLoading: isUploadingFiles }] = useUploadFiles();
  const promptCounter = usePromptCounter();
  const { classes } = useStyles(promptCounter);

  const isValid = useMemo(
    () => isResponding && request.trim().length && promptCounter.counterStatus !== 'error' && !isUploadingFiles,
    [isResponding, request, promptCounter, isUploadingFiles]
  );

  const handleResponse = async () => {
    let conversationId = conversation.id;
    if (!conversation.id) {
      const response = await createConversation({ conversation: { name: request }, organisationId });
      if ('data' in response) conversationId = response?.data?.id;
    }
    getResponse({ id: conversationId, text: request, type: 'User', organisationId: organisationId, dataSource: files });
    setRequest('');
    setFiles([]);
  };

  const addFiles = async (files: File[]) => {
    setFiles(fs => [...fs, ...files.map(file => ({ title: file.name.replace(/\.[^/.]+$/, '') }))]);
    const response = await uploadFiles(files);
    if (response.data) {
      setFiles(fs => fs.map(f => response.data.find(file => !f.state && f.title === file.title) ?? f));
    } else if (response.error) {
      setFiles(fs => fs.map(f => (files.some(file => file.name === f.title) ? { ...f, status: 'InsertFailed' } : f)));
    }
  };

  const removeFile = (file: ResourceDto) => {
    setFiles(files => files.filter(f => (file.filePath ? f.filePath !== file.filePath : f.title !== file.title)));
  };

  return (
    <Box className={classes.root}>
      <Stack>
        <Messages conversation={conversation} />
        {lastConversationIdError === conversation.id && (
          <Alert severity="error">Eroare: A apărut o eroare neașteptată. Vă rugăm să încercați din nou.</Alert>
        )}
        <Box className={classes.inputWrapper}>
          <TextField
            fullWidth
            className={classes.input}
            placeholder="Mesaj"
            multiline
            maxRows={8}
            value={request}
            InputProps={{
              startAdornment: (
                <ConversationActions
                  files={files}
                  addFiles={addFiles}
                  removeFile={removeFile}
                  setRequest={setRequest}
                />
              ),
              endAdornment: (
                <IconButton
                  disableRipple
                  disabled={!isValid}
                  onClick={handleResponse}
                  className={classes.adornmentEnd}
                  size="small"
                >
                  <SendIcon fontSize="small" />
                </IconButton>
              ),
            }}
            onChange={({ target: { value } }) => setRequest(value)}
            onKeyDown={event => {
              if (isValid && event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleResponse();
              }
            }}
          />
          <Stack direction="row" className={classes.info}>
            <InfoOutlinedIcon fontSize="small" />
            <Typography variant="caption">
              {site?.title} poate face greșeli. Recomandăm verificarea informațiilor importante.
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const usePromptCounter = (): { counterStatus: 'success' | 'info' | 'warning' | 'error'; counterMessage: string } => {
  const { data: userChatInfo } = useUserChatInfo();
  const { size, limit } = useMemo(
    () => ({ size: userChatInfo?.promptCounter || 0, limit: userChatInfo?.promptLimit || 0 }),
    [userChatInfo]
  );

  const counterMessage = `${size} din ${limit} mesaje`;
  const messagesLeft = limit - size;
  switch (true) {
    case messagesLeft <= 0:
      return { counterStatus: 'error', counterMessage };
    case messagesLeft <= 5:
      return { counterStatus: 'warning', counterMessage };
    case messagesLeft <= 10:
      return { counterStatus: 'info', counterMessage };
    default:
      return { counterStatus: 'success', counterMessage };
  }
};
