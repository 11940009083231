import {
  Avatar,
  CopyToClipboard,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useSite,
  useTheme,
} from '@sgde/core';
import { useEffect, useState } from 'react';
import { FeedbackDto } from '../../models/dto/chat/feedback.ts';
import { ResourceDto } from '../../models/dto/chat/resource.ts';
import { useMarkdownFormatter } from '../../utils/Markdown.tsx';
import { useStyles } from './Message.styles.ts';
import { MessageFeedback } from './MessageFeedback.tsx';
import { MessageFiles } from './MessageFiles.tsx';

type MessageProps = {
  variant: 'question' | 'response';
  message: string;
  files?: ResourceDto[];
  feedback?: FeedbackDto;
};

export const Message = ({ variant, message, files, feedback }: MessageProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { data: site } = useSite();
  const { format } = useMarkdownFormatter();
  const [avatar, setAvatar] = useState<string>();
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)');
  const isEmpty = message.length === 0;

  useEffect(() => {
    if (site) {
      setAvatar((isSystemDark && site.faviconDark ? site.faviconDark : site.favicon).url);
    }
  }, [isSystemDark, site, theme.palette.mode]);

  return (
    <Stack direction="row" className={classes.message}>
      <Avatar className={classes.avatar} src={variant === 'question' ? undefined : avatar} />
      {isEmpty && <LinearProgress sx={{ width: 200 }} />}
      {!isEmpty && (
        <Stack gap={1}>
          {variant === 'response' ? format(message) : <Typography component="pre">{message}</Typography>}
          {files?.length ? <MessageFiles files={files} /> : null}
          {variant === 'response' && (
            <Stack direction="row" gap={1}>
              <CopyToClipboard text={message} />
              <MessageFeedback {...feedback} />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
