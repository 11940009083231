import { ThemeMode, Typography, useTheme } from '@sgde/core';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';
import { Marked, RendererObject, TokenizerAndRendererExtension } from 'marked';
import { markedHighlight } from 'marked-highlight';
import { ReactElement, useEffect, useState } from 'react';

import darkTheme from 'highlight.js/styles/github-dark-dimmed.css?inline';
import lightTheme from 'highlight.js/styles/github.css?inline';

const postprocess = (dirtyMessage: string) =>
  DOMPurify.sanitize(dirtyMessage, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] });
export const useMarkdownFormatter = () => {
  const [marked, setMarked] = useState<Marked | undefined>();
  const theme = useTheme();

  useEffect(() => {
    const marked = new Marked(
      markedHighlight({
        emptyLangClass: 'hljs',
        langPrefix: 'hljs language-',
        highlight(code, lang) {
          const language = hljs.getLanguage(lang) ? lang : 'plaintext';
          return hljs.highlight(code, { language }).value;
        },
      })
    );

    marked.use({
      gfm: true,
      breaks: true,
      hooks: { postprocess },
      renderer: linkRenderer,
      extensions: [captionId, captionResource],
    });
    setMarked(marked);
  }, []);

  return {
    format: (message: string): ReactElement => (
      <>
        <style>{theme?.palette.mode === ThemeMode.Dark ? darkTheme : lightTheme}</style>
        <Typography component="div" dangerouslySetInnerHTML={{ __html: marked?.parse(message) ?? '' }} />
      </>
    ),
  };
};

type MarkedRuleOptions = {
  name: string;
  token: string;
  render: (text: string) => string;
};

const markedRule = ({ name, token, render }: MarkedRuleOptions): TokenizerAndRendererExtension => ({
  name,
  level: 'inline',
  start: src => src.indexOf(token),
  tokenizer(src) {
    const match = new RegExp(`${token}(.*?)(?=${token}|$)`).exec(src);
    return match
      ? {
          type: name,
          raw: match[0],
          text: this.lexer.inlineTokens(match[1]?.trim()),
        }
      : undefined;
  },
  renderer(token) {
    return token.type === name ? render(this.parser.parseInline(token.text)) : '';
  },
});

const captionId = markedRule({
  name: 'captionId',
  token: '¶¶',
  render: (text: string) => {
    const match = /^([0-9]+)(.*?)$/.exec(text);
    return match !== null ? `<span class="caption-id">${match[1]}</span>${match[2]}` : text;
  },
});

const captionResource = markedRule({
  name: 'captionResource',
  token: '§§',
  render: (text: string) => `<span class="caption-resource">${text}</span>`,
});

const linkRenderer = {
  link({ href, title, tokens }) {
    const text = this.parser.parseInline(tokens);
    return `<a title="${title ?? ''}" href="${encodeURI(href).replace(/%25/g, '%')}" target="_blank" rel="noopener">${text}</a>`;
  },
} as RendererObject;
