import { Chip, Stack } from '@sgde/core';
import { ResourceDto } from '../../models/dto/chat/resource.ts';
import { useStyles } from './MessageFiles.styles.ts';

type MessageFilesProps = {
  files: ResourceDto[];
  removeFile?: (file: ResourceDto) => void;
};

export const MessageFiles = ({ files, removeFile }: MessageFilesProps) => {
  const { classes } = useStyles();

  return (
    <Stack className={classes.root}>
      {files.map(file => (
        <Chip
          key={file.filePath ?? file.title}
          label={file.title}
          size="small"
          variant="outlined"
          onClick={file.url ? () => window.open(file.url, '_blank') : undefined}
          disabled={!file.state}
          color={file.state === 'Inserted' ? 'success' : file.state === 'InsertFailed' ? 'error' : 'default'}
          onDelete={removeFile ? () => removeFile(file) : undefined}
        />
      ))}
    </Stack>
  );
};
